.container-slider {
  /* max-width: 100%; */
  width: 100%;
  height: 100vh;
  /* margin: 5px 0; */
  position: relative;
  z-index: -1;
  overflow: hidden;
  /* border-radius: 20px; */
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); */
}
@media screen and (max-width: 700px) {
  .container-slider {
    max-height: 40vh;
  }
}

.slide {
  width: 100%;
  height: auto;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}
.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.active-anim {
  opacity: 1;
}

@media screen and (max-width: 700px) {
  .slide {
    height: auto;
  }

  .slide img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.slide-content {
  position: absolute;
  z-index: 99;
  width: 100%;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 480px) {
  .slide-content {
    top: 39%;
  }
}

.slide-content h1 {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #f1f1f1;
  max-width: 100%;
  padding: 10px;
}

@media screen and (min-width: 480px) {
  .slide-content h1 {
    max-width: 100%;
    color: #f1f1f1;
  }
}

.btn-slide {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #f1f1f1;
  border: 1px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .btn-slide {
    display: none;
  }
}

.btn-slide img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}
.prev {
  top: 50%;
  left: 20px;
  transform: translateY(-60%);
}
.next {
  top: 50%;
  right: 20px;
  transform: translateY(-60%);
}

.overflowX {
  overflow-x: scroll;
  padding-top: 0.6rem;
}

.dot {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  margin: 0 3px;
  filter: grayscale(100%);
}

.dot.active {
  filter: none;
}
