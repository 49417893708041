@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500);
* {
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
}
body {
  font-family: "Roboto", sans-serif;
}
.sidebar {
  position: fixed;
  left: 0px;
  width: 250px;
  /* background-color: aqua; */
  top: 0px;
  height: 100%;
  /* background: darkgray; */
  transition: all 0.5s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.sidebar header {
  /* font-size: 22px; */
  /* color: white; */
  /* line-height: 70px; */
  text-align: center;
  /* background: #063146; */
  -webkit-user-select: none;
          user-select: none;
  padding: 25px;
}
.sidebar ul a {
  display: block;
  height: 100%;
  width: 100%;
  line-height: 45px;
  font-size: 16px;
  color: #1c1c1c;
  /* text-decoration: none; */
  padding-left: 30px;
  box-sizing: border-box;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
  transition: 0.4s;
}

.sidebar ul button {
  display: block;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
  height: 100%;
  font-size: 17px;
  color: #1c1c1c;
  box-sizing: border-box;
  line-height: 45px;
  margin-left: 30px;
  transition: 0.4s;
}

.sidebar ul button:hover {
  color: red;
}

ul li:hover button {
  padding-left: 8px;
}

.sidebar ul a:hover {
  color: red;
}

ul li:hover a {
  padding-left: 40px;
}
.sidebar ul a i {
  margin-right: 16px;
}
#check {
  display: none;
}
label #btn,
label #cancel {
  /* position: absolute; */
  z-index: 1;
  /* background: #042331; */
  /* border-radius: 3px; */
  cursor: pointer;
}
label #btn {
  /* left: 100px; */
  z-index: 1;
  top: 25px;
  width: 150%;
  /* font-size: 5px; */
  /* color: white; */
  color: white;
  /* padding: 6px 12px; */
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s;
}
label #cancel {
  /* z-index: 1; */
  display: none;
  /* left: 10px; */
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); */
  /* margin-left: -20px; */
  /* top: 17px; */
  /* margin-top: 0px; */
  font-size: 35px;
  border-radius: 30px;
  color: white;
  background-color: black;
  /* padding: 20px 10px 0px 40px; */
  padding: 8px 6px 0 6px;
  margin-left: 250px;
  /* top: 30px; */
  transition: all 0.5s ease;
}

@media screen and (max-width: 768px) {
  label #cancel {
    display: block;
  }

  section {
    /* margin-right: 1000px; */
    width: 100%;
  }
}

label #cancel:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#check:checked ~ .sidebar {
  left: -250px;
}

#check:checked ~ label #btn {
  left: 0px;
  opacity: 1;
  pointer-events: auto;
}
#check:checked ~ label #cancel {
  /* left: 0px; */
  /* top: 20px; */
  margin-left: 20px;
}
#check:checked ~ section {
  margin-left: 0px;
}
section {
  /* width: 100%; */
  /* background: url(bg.jpeg) no-repeat; */
  /* background-position: center;
  background-size: cover; */
  /* height: 100vh; */
  /* overflow-x: auto; */
  transition: all 0.5s;
  margin-left: 250px;
}

.container-slider {
  /* max-width: 100%; */
  width: 100%;
  height: 100vh;
  /* margin: 5px 0; */
  position: relative;
  z-index: -1;
  overflow: hidden;
  /* border-radius: 20px; */
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); */
}
@media screen and (max-width: 700px) {
  .container-slider {
    max-height: 40vh;
  }
}

.slide {
  width: 100%;
  height: auto;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}
.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.active-anim {
  opacity: 1;
}

@media screen and (max-width: 700px) {
  .slide {
    height: auto;
  }

  .slide img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.slide-content {
  position: absolute;
  z-index: 99;
  width: 100%;
  top: 80%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 480px) {
  .slide-content {
    top: 39%;
  }
}

.slide-content h1 {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #f1f1f1;
  max-width: 100%;
  padding: 10px;
}

@media screen and (min-width: 480px) {
  .slide-content h1 {
    max-width: 100%;
    color: #f1f1f1;
  }
}

.btn-slide {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #f1f1f1;
  border: 1px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .btn-slide {
    display: none;
  }
}

.btn-slide img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}
.prev {
  top: 50%;
  left: 20px;
  -webkit-transform: translateY(-60%);
          transform: translateY(-60%);
}
.next {
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-60%);
          transform: translateY(-60%);
}

.overflowX {
  overflow-x: scroll;
  padding-top: 0.6rem;
}

.dot {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  margin: 0 3px;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.dot.active {
  -webkit-filter: none;
          filter: none;
}

